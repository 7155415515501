<script setup lang="ts">
import ActivityRow from './ActivityRow.vue'
import { usePromptStore } from '@/store/prompts'
import { storeToRefs } from 'pinia'
const promptStore = usePromptStore()

const { logs } = storeToRefs(promptStore)
</script>

<template>
  <section>
    <p :class="$style.title">Activity history</p>
    <p :class="$style.description">Showing history of updates made.</p>
    <table :class="$style.table">
      <ActivityRow v-for="log in logs" :key="log.item" :log="log" />
    </table>
  </section>
</template>

<style module lang="scss">
.table {
  width: 100%;
  margin-top: 32px;
}

.title {
  color: var(--neutral-12);
  @extend .semibold-4;
}

.description {
  color: var(--neutral-11);
  @extend .regular-4;
}
</style>
