import useApi, { useWorkspaceApi } from '@/composables/api'
import type User from '@/models/user'
import type Workspace from '@/models/workspace'
import { acceptHMRUpdate, defineStore } from 'pinia'
import router from '@/routes'
import type { DropDownItem } from '@/components/common'
import type WorkspaceInvitation from '@/models/workspaceInvitation'
import type { WorkspaceMember } from '@/models/user'

type WorkspaceState = {
  workspaces: Workspace[]
  members: Array<WorkspaceMember>
  invitations: Array<WorkspaceInvitation>
} & ReturnType<typeof useWorkspaceApi>

export const useWorkspaceStore = defineStore('workspace', {
  state: (): WorkspaceState => ({
    workspaces: [],
    members: [],
    invitations: [],
    ...useWorkspaceApi(),
  }),
  getters: {
    workspace: (state): Workspace | undefined => {
      const route = router.currentRoute.value
      const workspaceId = route.params.workspaceId as string
      const workspace = state.workspaces.find(
        (workspace) => workspace.id === parseInt(workspaceId),
      )
      return workspace
    },
    embedCode() {
      const currentWorkspace = this.workspace as Workspace
      const embedCode = `&#x3C;!-- Start of Vanilla Inbox Widget script --&#x3E;
<br />
&#x3C;script type=&#x22;text/javascript&#x22;&#x3E;window.vanilla = { workspaceId: &#x27;${currentWorkspace?.publicId}&#x27; }&#x3C;/script&#x3E;
<br />
&#x3C;script id=&#x22;van-snippet&#x22; async src=&#x22;${import.meta.env.VITE_EMBED_HOST}/vanilla.js&#x22;&#x3E;&#x3C;/script&#x3E;
<br />
&#x3C;!-- End of Vanilla Inbox Widget script --&#x3E;`

      return embedCode
    },
  },
  actions: {
    setWorkspaces(workspaces: Workspace[]) {
      this.workspaces = workspaces
    },
    setCurrentWorkspace(id: number) {
      this.api.client.defaults.baseURL = `${import.meta.env.VITE_API_HOST}${id}`
    },
    async getMembers() {
      this.members = await this.api.workspace.members()
      return this.members
    },
    async getInvitations() {
      this.invitations = await this.api.workspace.invitations()
      return this.invitations
    },
    async createWorkspace(payload: Pick<Workspace, 'title'>) {
      const { api } = useApi()
      const data = await api.workspace.create(payload)
      this.workspaces.push(data)
      return data
    },
    async deleteCurrentWorkspace() {
      const current = this.workspace

      await this.api.workspace.deleteWorkspace()
      const workspaces = this.workspaces?.filter((e) => e.id !== current?.id)

      return workspaces?.[0]
    },
    async updateWorkspace(body: { title: string }, logo?: File) {
      const current = this.workspace
      if (!current) return

      logo && (await this.api.workspace.uploadLogo(logo))
      const workspace = await this.api.workspace.update(body)

      this.workspaces = (this.workspaces || []).map((w) =>
        w.id === current.id ? workspace : w,
      )
    },
    async inviteMember(email: string) {
      const data = await this.api.workspace.inviteMember(email)
      return this.invitations.push(data)
    },

    async updateRole(userId: number, role: WorkspaceMember['role']) {
      const index = this.members.findIndex((member) => member.id === userId)
      this.members[index].role = role
      return this.api.workspace.updateUserRole(userId, role)
    },

    async removeMember(userId: number) {
      const index = this.members.findIndex((member) => member.id === userId)
      this.members.splice(index, 1)
      return this.api.workspace.removeMember(userId)
    },

    async removeInvitation(id: number) {
      const index = this.invitations.findIndex((invite) => invite.id === id)
      this.invitations.splice(index, 1)
      return this.api.workspace.removeInvitation(id)
    },
    membersMenu(
      selectable: boolean,
      handler?: (id: User) => void,
    ): DropDownItem[] {
      return this.members.map((member) => ({
        id: member.id,
        title: member.name,
        selectable,
        handler: () => handler && handler(member),
      }))
    },
    async saveNotificationSettings(data: object) {
      await this.api.workspace.saveNotificationSettings(data)
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWorkspaceStore, import.meta.hot))
}
