<script setup lang="ts">
import { computed } from 'vue'
import { usePromptStore } from '@/store/prompts'
import { storeToRefs } from 'pinia'
import { Page, Breadcrumbs, type Crumb } from '@/components/common/layout'
import PromptRow from '@/components/shared/PromptRow.vue'
import { useRoute } from 'vue-router'

const promptStore = usePromptStore()
const route = useRoute()
const folderId = Number(route.params.id)
const { folderPrompts } = storeToRefs(promptStore)

const folderName = computed(() => {
  return promptStore.folders.find((folder) => folder.id === folderId)?.name
})

const crumbs = computed<Crumb[]>(() => [
  {
    title: folderName.value || '',
    url: { name: 'AllPrompts' },
  },
])

promptStore.getAllPromptsById(folderId)
</script>

<template>
  <Page :full-screen="true">
    <template #topBar>
      <Breadcrumbs :crumbs="crumbs" />
    </template>
    <div :class="$style.container">
      <div>
        <p :class="$style.title">All {{ folderName }} prompts</p>
        <p :class="$style.description">
          Place where all your folder prompts are located.
        </p>
      </div>
      <table>
        <PromptRow
          v-for="prompt in folderPrompts"
          :key="prompt.id"
          :prompt="prompt"
        />
      </table>
    </div>
  </Page>
</template>

<style module lang="scss">
.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 32px;
  padding: 32px 32px 64px 32px;
  .content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 772px;
    margin: 0 auto;
  }
}

.title {
  color: var(--neutral-12);
  @extend .semibold-4;
}

.description {
  color: var(--neutral-11);
  @extend .regular-4;
}
</style>
