<script setup lang="ts">
import PromptRow from '@/components/shared/PromptRow.vue'
import { usePromptStore } from '@/store/prompts'

const promptStore = usePromptStore()
</script>

<template>
  <section>
    <p :class="$style.title">Recent prompts</p>
    <p :class="$style.description">
      Showing history of most recent opened prompts.
    </p>
    <table :class="$style.table">
      <PromptRow
        v-for="prompt in promptStore.topSixPrompts"
        :key="prompt.id"
        :prompt="prompt"
      />
    </table>
  </section>
</template>

<style module lang="scss">
.table {
  width: 100%;
  margin-top: 32px;
}

.title {
  color: var(--neutral-12);
  @extend .semibold-4;
}

.description {
  color: var(--neutral-11);
  @extend .regular-4;
}
</style>
