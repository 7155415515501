import { DateTime } from 'luxon'

export const ISODate = (
  date: string,
  {
    format,
    time = false,
    separator = ' ',
  }: { format?: string; time?: boolean; separator?: string },
): string => {
  if (!format && !time) return ''
  const luxon = DateTime.fromISO(date)

  // Check if the date is within the past 24 hours
  const now = DateTime.now()
  const diffInHours = now.diff(luxon, 'hours').hours
  if (diffInHours < 24 && diffInHours >= 0) {
    const relativeTime = luxon.toRelative({ base: now })
    if (relativeTime) {
      return relativeTime
    }
  }

  let dateFormat = format
  if (luxon.hasSame(now, 'day')) {
    dateFormat = `'Today'`
  } else if (luxon.hasSame(now.minus({ days: 1 }), 'day')) {
    dateFormat = `'Yesterday'`
  }

  const finalFormat = [
    ...(dateFormat ? [dateFormat] : []),
    ...(dateFormat && time ? [separator] : []),
    ...(time ? ['hh:mm a'] : []),
  ].join('')
  return luxon.toFormat(finalFormat)
}

export const fileToPreview = (file: File) => {
  if (!file) return null
  return window.URL.createObjectURL(file)
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const sortByCreatedAtDesc = <T extends { createdAt: string }>(
  arr: T[],
): T[] => {
  return [...arr].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
  )
}
