<script setup lang="ts">
import { useRoute } from 'vue-router'
import $style from './styles.module.scss'
import { Icon, Avatar, Popover, UpgradePopupKey } from '@/components/common'
import { useWorkspaceStore } from '@/store/workspace'
import { useApplicationStore } from '@/store'
import { provide, ref } from 'vue'
import ProfileMenu from '@/components/Dashboard/ProfileMenu.vue'
import BillingBanner from '@/components/Dashboard/BillingBanner.vue'
import UpgradeFlow from '@/components/payment/UpgradeFlow.vue'
import useWebsockets from '@/composables/websockets.ts'
import type { Menu } from './constants'

const route = useRoute()
const socket = useWebsockets()
const upgradeFlow = ref<typeof UpgradeFlow>()

const workspaceId = Number(route.params.workspaceId)

const appStore = useApplicationStore()
const workspaceStore = useWorkspaceStore()
workspaceStore.setCurrentWorkspace(workspaceId)
workspaceStore.getMembers()

const apps: Menu[] = [
  {
    children: [
      {
        title: 'Dashboard',
        url: { name: 'Dashboard' },
        icon: 'layoutGrid2',
      },
      {
        title: 'All Prompts',
        url: { name: 'AllPrompts' },
        icon: 'box2AltFill',
      },
    ],
  },
  // {
  //   groupName: 'Favorite folders',
  //   children: [
  //     {
  //       title: 'Social Media',
  //       icon: 'folder2',
  //       color: '#C62A2F',
  //       url: { name: 'Favorite', params: { id: 1 } },
  //     },
  //     {
  //       title: 'Emails',
  //       icon: 'folder2',
  //       color: '#2756E1',
  //       url: { name: 'Favorite', params: { id: 2 } },
  //     },
  //     {
  //       title: 'App',
  //       icon: 'folder2',
  //       color: '#7B39D0',
  //       url: { name: 'Favorite', params: { id: 3 } },
  //     },
  //   ],
  // },
]
provide('workspaceId', workspaceId)
provide(UpgradePopupKey.show, () => upgradeFlow.value?.triggerUpgrade())
provide('socket', socket)
</script>

<template>
  <UpgradeFlow ref="upgradeFlow" />
  <Transition name="slide-left">
    <nav v-if="appStore.sidebars.left" :class="$style.navigation">
      <div :class="$style.topBar">
        <h2 :class="$style.logo">prompthub</h2>
        <Popover placement="bottom-start">
          <template #content>
            <ProfileMenu />
          </template>
          <Avatar
            :title="appStore.user?.name"
            :src="appStore.user?.avatar"
            :size="24"
          />
        </Popover>
      </div>
      <ul :class="$style.content">
        <li :class="$style.gap" />
        <div
          v-for="{ groupName, children } in apps"
          :key="groupName"
          :class="$style.group"
        >
          <div v-if="groupName" :class="$style.groupName">{{ groupName }}</div>

          <ul :class="$style.group">
            <li v-for="{ title, url, icon, color } in children" :key="title">
              <router-link
                :to="url"
                :class="$style.route"
                :active-class="$style.active"
              >
                <Icon
                  v-if="icon"
                  :icon="icon"
                  :size="18"
                  :style="{ color: color }"
                />
                {{ title }}
              </router-link>
            </li>
          </ul>
        </div>
        <li :class="$style.gap" />
      </ul>

      <BillingBanner v-if="!workspaceStore.workspace?.isPremium" />
    </nav>
  </Transition>
  <router-view />
</template>
