import { keymap } from 'prosemirror-keymap'
import { history } from 'prosemirror-history'
import { baseKeymap } from 'prosemirror-commands'
import { EditorState, Plugin, PluginKey } from 'prosemirror-state'
import { dropCursor } from 'prosemirror-dropcursor'
import { gapCursor } from 'prosemirror-gapcursor'
import { menuBar } from 'prosemirror-menu'
import { DecorationSet, Decoration, EditorView } from 'prosemirror-view'
import { buildMenuItems, composeMenu } from './menu'
import { buildKeymap } from './keymap'
import { buildInputRules } from './inputrules'
import FlyingMenu from './FlyingMenu'
import type { Schema } from 'prosemirror-model'

export { buildMenuItems, buildKeymap, buildInputRules }

interface Options {
  plugins: Plugin[]
  schema: Schema
  history?: boolean
  placeholder: string
  menuBar?: boolean
  flying: boolean
}

function openLinkInNewTab(href: string) {
  const a = document.createElement('a')
  a.href = href
  a.target = '_blank'
  a.rel = 'noopener noreferrer'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

function linkifyPlugin() {
  return new Plugin({
    key: new PluginKey('linkify'),
    props: {
      handleClick(view: EditorView, pos: number, event: MouseEvent) {
        const { schema } = view.state
        const $pos = view.state.doc.resolve(pos)
        const node = $pos.nodeAfter

        if (node && node.marks) {
          const linkMark = node.marks.find(
            (mark) => mark.type === schema.marks.link,
          )
          if (linkMark) {
            const href = linkMark.attrs.href
            if (event.ctrlKey) {
              openLinkInNewTab(href)
              return true
            }
          }
        }
        return false
      },
      decorations(state: EditorState) {
        const decorations: Decoration[] = []
        state.doc.descendants((node, pos) => {
          node.marks.forEach((mark) => {
            if (mark.type.name === 'link') {
              decorations.push(
                Decoration.inline(pos, pos + node.nodeSize, {
                  title: 'CTRL+CLICK to open link',
                }),
              )
            }
          })
        })
        return DecorationSet.create(state.doc, decorations)
      },
    },
  })
}

export function editorSetup(options: Options) {
  const plugins = [
    ...options.plugins,
    buildInputRules(options.schema),
    keymap(buildKeymap(options.schema)),
    keymap(baseKeymap),
    dropCursor(),
    gapCursor(),
    linkifyPlugin(),
  ]

  if (options.menuBar) {
    plugins.push(
      menuBar({
        content: composeMenu(options.schema),
      }),
    )
  } else {
    plugins.push(
      new Plugin({
        view(editorView) {
          return new FlyingMenu(editorView, {
            menu: composeMenu(options.schema),
          })
        },
      }),
    )
  }

  if (options.history !== false) plugins.push(history())

  plugins.push(
    new Plugin({
      key: new PluginKey('placeholder'),
      state: {
        init() {
          return { placeholder: options.placeholder }
        },
        apply(tr, state) {
          return { placeholder: tr.getMeta('placeholder') || state.placeholder }
        },
      },
      props: {
        attributes: (state: EditorState): { [key: string]: string } => {
          const doc = state.doc
          if (
            doc.childCount === 1 &&
            doc.firstChild?.isTextblock &&
            doc.firstChild.content.size === 0
          ) {
            return { class: `placeholder` }
          }
          return {}
        },
        decorations(state: EditorState) {
          const pluginState = this.getState(state)

          const doc = state.doc
          if (
            doc.childCount === 1 &&
            doc.firstChild?.isTextblock &&
            doc.firstChild.content.size === 0
          )
            return DecorationSet.create(doc, [
              Decoration.widget(
                1,
                document.createTextNode(
                  pluginState?.placeholder ||
                    options.placeholder ||
                    'Type Something',
                ),
              ),
            ])
        },
      },
    }),
  )

  return plugins.concat(
    new Plugin({
      props: {
        attributes: { class: `rich-text ${options.flying && 'flying'}` },
      },
    }),
  )
}
