import axios, { type AxiosInstance } from 'axios'
import { AccountService } from './account'
import { AuthService } from './auth'
import type { Pagination } from './types'
import { WorkspaceService } from './workspace'
import { TagService } from './tag'
import { PromptService } from './prompt'

export default class Api {
  client: AxiosInstance

  auth: AuthService
  account: AccountService
  workspace: WorkspaceService
  tag: TagService
  prompt: PromptService

  constructor(baseUrl?: string) {
    // build client
    this.client = axios.create({
      baseURL: baseUrl ?? import.meta.env.VITE_API_HOST,
      withCredentials: true,
      withXSRFToken: true,
    })

    this.auth = new AuthService(this.client)
    this.account = new AccountService(this.client)
    this.workspace = new WorkspaceService(this.client)
    this.tag = new TagService(this.client)
    this.prompt = new PromptService(this.client)
  }

  loadNextPage = <T>(url: string) => {
    return this.client.get<Pagination<T>>(url).then((response) => response.data)
  }
}
