<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { Button, Slider, Checkbox } from '@/components/common'
import { usePromptStore } from '@/store/prompts'

const promptStore = usePromptStore()
const { tokens, checkboxList, prompt, isLoading } = storeToRefs(promptStore)

const emits = defineEmits(['close'])

const runPrompt = () => {
  promptStore.runPromptWithStatus().then(() => {
    emits('close')
  })
}
</script>

<template>
  <div :class="['popup-content', $style.container]">
    <p>
      You're about to run
      <span :class="$style.title">{{ prompt?.name }}</span> prompt. Please,
      configure how you want to run it.
    </p>
    <div :class="$style.sliderWrapper">
      <p :class="$style.title">Tokens</p>
      <div :class="$style.slider">
        <p>0</p>
        <Slider v-model="tokens" :min="0" :max="10000" />
        <p>10,000</p>
      </div>
      <p :class="$style.description">
        The more tokens you are willing to spend, the more detailed result would
        be.
      </p>
    </div>
    <div :class="$style.checkboxes">
      <p :class="$style.title">LLM's</p>
      <div
        v-for="(checkbox, index) in checkboxList"
        :key="checkbox.name"
        :class="$style.checkBoxItem"
      >
        <Checkbox
          :model-value="checkbox.value"
          @update:model-value="
            (checked) => promptStore.toggleCheckbox(index, checked)
          "
        >
          {{ checkbox.name }}
        </Checkbox>
      </div>
    </div>
  </div>
  <div :class="$style.actions">
    <Button size="3" theme="neutral" variant="outline" @click="$emit('close')">
      Cancel
    </Button>
    <Button
      size="3"
      theme="neutral"
      variant="solid"
      :loading="isLoading"
      @click="runPrompt"
    >
      Run prompt
    </Button>
  </div>
</template>

<style module lang="scss">
.container {
  width: 340px;
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  gap: 24px;
  p {
    color: var(--neutral-11);
    @extend .regular-3;
  }
  .sliderWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .title {
      color: var(--neutral-12);
      @extend .medium-3;
    }
    .description {
      color: var(--neutral-11);
      @extend .regular-2;
    }
    .slider {
      display: flex;
      flex-direction: row;
      gap: 16px;
      p {
        margin: auto 0;
        color: var(--neutral-12);
        @extend .regular-3;
      }
    }
  }
  .checkboxes {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .title {
      color: var(--neutral-12);
      @extend .medium-3;
    }
    .checkBoxItem {
      display: flex;
      flex-direction: row;
      gap: 12px;
    }
  }
}
.title {
  color: var(--neutral-12);
  @extend .medium-3;
}
.actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 16px;
  border-top: 1px solid var(--neutral-alpha-4);
}
</style>
