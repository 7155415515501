<script setup lang="ts">
import { reactive, computed } from 'vue'
import { ISODate } from '@/utils/helpers'
import { storeToRefs } from 'pinia'
import { Button, Icon } from '@/components/common'
import { usePromptStore } from '@/store/prompts'
import { onBeforeUnmount } from 'vue'

const promptStore = usePromptStore()
const openPlatforms = reactive<Record<string, boolean>>({})
const { result } = storeToRefs(promptStore)

const togglePlatform = (platform: string) => {
  openPlatforms[platform] = !openPlatforms[platform]
}

const formattedDate = computed(() => {
  if (result.value?.createdAt) {
    return ISODate(result.value?.createdAt, { format: 'LLL dd' })
  }
  return ''
})

onBeforeUnmount(() => {
  result.value = null
})
</script>

<template>
  <div :class="$style.content">
    <div :class="$style.promptText">
      <Button
        theme="neutral"
        variant="ghost"
        icon="chevronLeftSmall"
        :class="$style.back"
        @click="result = null"
      >
        Back to prompt
      </Button>
      <p :class="$style.title">{{ result?.name }}</p>
      <p :class="$style.description">{{ result?.prompt }}</p>
      <div :class="$style.info">
        <p>Max {{ result?.tokens }} tokens</p>
        <span>.</span>
        <p>{{ result?.platforms.length }} LLMs</p>
        <span>.</span>
        <p>{{ formattedDate }}</p>
      </div>
    </div>
    <hr />
    <div :class="$style.results">
      <div :class="$style.headings">
        <p :class="$style.title">Prompt run results</p>
        <p :class="$style.description">
          Results of your prompt, run on selected LLMs
        </p>
      </div>
      <div :class="$style.result">
        <div
          v-for="platform in result?.platforms"
          :key="platform"
          :class="$style.output"
          @click="togglePlatform(platform)"
        >
          <div :class="$style.resultInfo">
            <p>{{ platform }}</p>
            <Icon
              :size="24"
              icon="chevronDownSmall"
              :class="[
                $style.icon,
                { [$style.rotated]: openPlatforms[platform] },
              ]"
            />
          </div>

          <div v-if="openPlatforms[platform]" :class="$style.outputContent">
            {{ result?.outputs[platform] || result?.errors[platform] }}
          </div>
          <div :class="$style.info">
            <p>{{ result?.tokens }} tokens</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 772px;
  margin: 0 auto;
  .promptText {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .title {
      @extend .semibold-4;
      color: var(--neutral-12);
    }
    .description {
      color: var(--neutral-12);
      @extend .regular-3;
    }
    .back {
      color: var(--neutral-alpha-11);
      @extend .medium-2;
      width: max-content;
      height: 20px;
    }
    .info {
      display: flex;
      gap: 6px;
      p {
        color: var(--neutral-11);
        @extend .regular-3;
      }
      span {
        margin: -2px 0;
      }
    }
  }

  .results {
    display: flex;
    flex-direction: column;
    gap: 32px;
    .headings {
      .title {
        @extend .semibold-4;
        color: var(--neutral-12);
      }
      .description {
        color: var(--neutral-11);
        @extend .regular-3;
      }
    }
    .result {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .output {
        display: flex;
        flex-direction: column;
        gap: 4px;
        border-radius: 12px;
        padding: 12px;
        background: var(--neutral-alpha-3);
        cursor: pointer;
        .resultInfo {
          display: flex;
          justify-content: space-between;
          align-items: center;
          p {
            color: var(--neutral-12);
            text-transform: uppercase;
            @extend .semibold-3;
          }
        }
        .icon {
          transition: transform 0.3s ease;
          &.rotated {
            transform: rotate(180deg);
          }
        }
        .outputContent {
          margin-top: 8px;
          color: var(--neutral-11);
          @extend .regular-3;
          white-space: pre-wrap;
          overflow: hidden;
        }
        .info {
          display: flex;
          gap: 6px;
          p {
            color: var(--neutral-11);
            @extend .regular-3;
          }
          span {
            margin: -2px 0;
          }
        }
      }
    }
  }
}
</style>
