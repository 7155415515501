import { createRouter, createWebHistory } from 'vue-router'

import UIKit from '@/pages/UIKit.vue'
import Splash from '@/pages/Splash.vue'
import Workspace from '@/pages/Dashboard/index.vue'
import type { RouteRecordRaw } from 'vue-router'
import AuthLogin from '@/pages/auth/Login.vue'
import AuthForgot from '@/pages/auth/Forgot.vue'
import AuthReset from '@/pages/auth/Reset.vue'
import AuthSignupWrapper from './pages/auth/SignupWrapper.vue'

import Onboarding from '@/pages/Onboarding/index.vue'
import Dashboard from '@/modules/dashboard/index.vue'

import SettingsRoutes from '@/modules/settings/routes'
import PromptsRoutes from '@/modules/prompts/routes'
import FavoriteRoutes from '@/modules/favorite/routes'
import FolderRoutes from '@/modules/folders/routes'

const routes: RouteRecordRaw[] = [
  {
    name: 'uikit',
    path: '/ui-kit',
    component: UIKit,
    meta: { title: 'UI Kit' },
  },
  {
    name: 'login',
    path: '/login',
    component: AuthLogin,
    meta: { title: 'Login' },
  },
  {
    name: 'signup',
    path: '/signup',
    component: AuthSignupWrapper,
    meta: { title: 'Signup' },
  },
  {
    name: 'forgot',
    path: '/forgot',
    component: AuthForgot,
    meta: { title: 'Forgot Password' },
  },
  {
    name: 'reset',
    path: '/reset/:token',
    component: AuthReset,
    meta: { title: 'Reset Password' },
  },
  {
    name: 'accept',
    path: '/accept-invite',
    component: AuthSignupWrapper,
    meta: { title: 'Accept Invite' },
  },

  {
    name: 'Splash',
    path: '/',
    component: Splash,
    meta: { title: 'Spash' },
  },

  {
    name: 'Onboarding',
    path: '/onboarding',
    component: Onboarding,
    meta: { title: 'On Boarding' },
  },

  {
    name: 'Workspace',
    path: '/:workspaceId(\\d+)',
    component: Workspace,
    redirect: { name: 'Dashboard' },
    meta: { title: 'Workspace' },
    children: [
      ...SettingsRoutes,
      ...PromptsRoutes,
      ...FavoriteRoutes,
      ...FolderRoutes,
      {
        name: 'Dashboard',
        path: 'dashboard',
        component: Dashboard,
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, _from, next) => {
  document.title = to.meta.title as string
  next()
})
export default router
