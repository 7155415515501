<script setup lang="ts">
import { ref, computed } from 'vue'
import { Page } from '@/components/common/layout'
import { Button, Popup } from '@/components/common'
import RecentPrompts from './components/RecentPrompts.vue'
import Activity from './components/Activity.vue'
import EmptyView from '@/components/common/EmptyView.vue'
import NewPromptForm from '@/modules/prompts/components/NewPromptForm.vue'
import InfoCards from './components/InfoCards.vue'
import { usePromptStore } from '@/store/prompts'

const promptStore = usePromptStore()

const isEmpty = computed(
  () => promptStore.prompts.length === 0 && promptStore.activities.length === 0,
)
const newPrompt = ref<InstanceType<typeof Popup> | null>(null)
promptStore.getAllPrompts()
</script>

<template>
  <Page :full-screen="true">
    <template #topBar> Dashboard </template>
    <div :class="$style.container">
      <div :class="$style.cards">
        <InfoCards />
      </div>
      <div v-if="isEmpty" :class="$style.emptyContainer">
        <EmptyView
          icon="folderOpen"
          title="You don’t have any prompts yet."
          description=" Right now, there’s no prompts in this workspace. Let’s create your first
        prompt!"
        />
        <Button
          theme="neutral"
          size="2"
          variant="solid"
          @click="newPrompt?.show()"
          >Create New Prompt</Button
        >
      </div>
      <div v-else :class="$style.promptContainer">
        <RecentPrompts />
        <hr />

        <Activity />
      </div>
    </div>
  </Page>
  <Popup ref="newPrompt" title="New Prompt">
    <NewPromptForm @close="newPrompt?.hide()" />
  </Popup>
</template>

<style module lang="scss">
.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 32px;
  padding: 32px 32px 64px 32px;

  .cards {
    display: flex;
    gap: 16px;
    justify-content: space-between;
  }
}

.emptyContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  padding-top: 64px;
}

.promptContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 32px;
}
</style>
