<script setup lang="ts">
import { usePromptStore } from '@/store/prompts'

const promptStore = usePromptStore()
promptStore.getAllFolders()
promptStore.getAllPrompts()
</script>
<template>
  <div :class="$style.card">
    <p :class="$style.title">Folders</p>
    <h2 :class="$style.value">{{ promptStore.folders.length }}</h2>
  </div>
  <div :class="$style.card">
    <p :class="$style.title">Prompts</p>
    <h2 :class="$style.value">{{ promptStore.prompts.length }}</h2>
  </div>
  <div :class="$style.card">
    <p :class="$style.title">Most used LLM</p>
    <h2 :class="$style.value">_ _ _</h2>
  </div>
  <div :class="$style.card">
    <p :class="$style.title">Avg. cost</p>
    <h2 :class="$style.value">_ _ _</h2>
  </div>
</template>

<style module lang="scss">
.card {
  padding: 12px 16px;
  flex: 1 1 calc(25% - 32px);
  min-width: 236px;
  border-radius: 8px;
  border: 1px solid var(--neutral-alpha-4);

  .title {
    color: var(--neutral-11);
    @extend .medium-3;
  }

  .value {
    color: var(--neutral-12);
    @extend .semibold-6;
  }
}
</style>
