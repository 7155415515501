import { RouteRecordRaw } from 'vue-router'
import Base from './index.vue'
import AllFolders from './AllFolders.vue'
import SingleFolder from './SingleFolder.vue'

const routes: RouteRecordRaw[] = [
  {
    name: 'Folders',
    path: 'folders',
    component: Base,
    redirect: { name: 'AllFolders' },
    children: [
      {
        name: 'AllFolders',
        path: '',
        component: AllFolders,
      },
      {
        name: 'Folder',
        path: ':id',
        component: SingleFolder,
      },
    ],
  },
]

export default routes
