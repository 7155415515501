<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { Page } from '@/components/common/layout'
import { useRoute } from 'vue-router'
import {
  Button,
  TextField,
  Icon,
  Popup,
  DropDown,
  type DropDownItem,
} from '@/components/common'
import { usePromptStore } from '@/store/prompts'
import NewPromptForm from '@/modules/prompts/components/NewPromptForm.vue'
import { storeToRefs } from 'pinia'
const route = useRoute()

const folderId = ref(Number(route.params.id))
const newPrompt = ref<InstanceType<typeof Popup> | null>(null)
const promptStore = usePromptStore()
const { folders } = storeToRefs(promptStore)
const fiteredFolder = computed(() => {
  return folders.value.find((folder) => folder.id === folderId.value) || null
})

const count = computed(() => {
  return fiteredFolder.value?.promptsCount || 0
})

watch(
  () => route.params.id,
  (newId) => {
    folderId.value = Number(newId)
  },
)

const menu: DropDownItem[] = [
  {
    id: 'Folder',
    title: 'Folder',
    icon: 'folder2',
    handler: () => console.log('create new Folder'),
  },
  {
    id: 'Prompt',
    title: 'Prompt',
    icon: 'fileText',
    handler: () => newPrompt.value?.show(),
  },
]
</script>

<template>
  <Page :full-screen="true">
    <template #topBar>
      {{ fiteredFolder?.name }}
      <div :class="$style.search">
        <TextField icon="" placeholder="Search Prompt">
          <template #pre>
            <Icon icon="magnifyingGlass" :size="20" />
          </template>
        </TextField>
        <DropDown :menu="menu" :width="144">
          <Button icon="plusSmall" size="2" theme="neutral" variant="solid">
            Create New
          </Button>
        </DropDown>
      </div>
    </template>
    <div :class="$style.container">
      <section>
        <p :class="$style.title">{{ fiteredFolder?.name }}</p>
        <p :class="$style.description">{{ count }} prompts</p>
        <table :class="$style.table">
          <!-- <PromptRow
            v-for="prompt in fiteredFolder"
            :prompt="prompt"
          /> -->
        </table>
      </section>
    </div>
    <Popup ref="newPrompt" title="New Prompt">
      <NewPromptForm />
    </Popup>
  </Page>
</template>

<style module lang="scss">
.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 32px;
  margin: 32px;
}

.search {
  display: flex;
  gap: 12px;
}

.table {
  width: 100%;
  margin-top: 32px;
}

.title {
  color: var(--neutral-12);
  @extend .semibold-4;
}

.description {
  color: var(--neutral-11);
  @extend .regular-3;
}
</style>
