<script setup lang="ts">
import { usePromptStore } from '@/store/prompts'
import { ISODate } from '@/utils/helpers'

const promptStore = usePromptStore()
</script>

<template>
  <section>
    <p :class="$style.title">Activity history</p>
    <p :class="$style.description">Showing history of updates made.</p>
    <div :class="$style.history">
      <div
        v-for="activity in promptStore.sortedActivities"
        :key="activity.id"
        :class="$style.historyContent"
      >
        <span :class="$style.name">{{ activity?.causer?.name }}</span>
        <span :class="$style.status">{{ activity.event }} prompt</span>
        <span :class="$style.date">{{
          ISODate(activity.createdAt, { format: 'LLL dd' })
        }}</span>
      </div>
    </div>
  </section>
</template>

<style module lang="scss">
.history {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 32px;
  .historyContent {
    display: flex;
    align-items: baseline;
    gap: 4px;
    .name {
      color: var(--neutral-12);
      @extend .medium-2;
    }
    .status {
      color: var(--neutral-11);
      @extend .regular-2;
    }
    .date {
      color: var(--neutral-9);
      @extend .medium-2;
    }
  }
}
.title {
  color: var(--neutral-12);
  @extend .semibold-4;
}

.description {
  color: var(--neutral-11);
  @extend .regular-4;
}
</style>
