import { RouteRecordRaw } from 'vue-router'
import Base from './index.vue'
import Favorite from './Favorite.vue'
const routes: RouteRecordRaw[] = [
  {
    name: 'Favorites',
    path: 'favorites',
    component: Base,
    redirect: { name: 'Favorite', params: { id: 1 } },
    children: [
      {
        name: 'Favorite',
        path: ':id',
        component: Favorite,
      },
    ],
  },
]

export default routes
