<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { reactive } from 'vue'
import { Checkbox, RichEditor } from '@/components/common'
import { PromptResponse } from '@/models/prompt'
import { watchDebounced } from '@vueuse/core'
import PromptCards from '@/modules/dashboard/components/PromptCards.vue'
import { usePromptStore } from '@/store/prompts'

const props = defineProps<{
  prompt: PromptResponse
}>()

const promptForm = reactive({
  promptTitle: props.prompt.master.name,
  promptText: props.prompt.master.prompt,
})

const promptStore = usePromptStore()
const { checkboxList } = storeToRefs(promptStore)
const handlePromptUpdate = () => {
  promptStore.updatePrompt(props.prompt.id, {
    name: promptForm.promptTitle,
    prompt: promptForm.promptText,
  })
  promptStore.getPromptActivity(props.prompt.id)
}
watchDebounced(promptForm, handlePromptUpdate, { debounce: 500, deep: true })
</script>

<template>
  <div :class="$style.promptText">
    <input
      v-model="promptForm.promptTitle"
      :class="$style.title"
      placeholder="Task title"
    />
    <RichEditor
      v-if="promptForm.promptText !== undefined"
      v-model="promptForm.promptText"
      :class="$style.details"
      placeholder="Task description..."
    />
  </div>
  <ul :class="$style.checkboxList">
    <li v-for="(checkbox, index) in checkboxList" :key="checkbox.name">
      <Checkbox
        :model-value="checkbox.value"
        @update:model-value="
          (checked) => promptStore.toggleCheckbox(index, checked)
        "
      >
        {{ checkbox.name }}
      </Checkbox>
    </li>
  </ul>
  <div :class="$style.cards">
    <PromptCards />
  </div>
</template>

<style module lang="scss">
.cards {
  display: flex;
  gap: 16px;
  justify-content: space-between;
}
.promptText {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .title {
    @extend .semibold-4;
    font-family: inherit;
    width: 100%;
    border: 0px;
    background-color: transparent;
    outline: none;
    padding: 0px;
    margin: 24px 0 12px;

    &::placeholder {
      color: var(--neutral-alpha-9);
    }
  }
  .description {
    color: var(--neutral-12);
    @extend .regular-3;
  }
}
.checkboxList {
  display: flex;
  gap: 30px;
  li {
    display: flex;
    gap: 12px;
    p {
      margin: auto 0;
      color: var(--neutral-12);
      @extend .medium-3;
    }
  }
}
</style>
