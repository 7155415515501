<script setup lang="ts">
import { ref, reactive, computed } from 'vue'
import { Page } from '@/components/common/layout'
import PromptFoldersTable from './components/PromptFoldersTable.vue'
import EmptyView from '@/components/common/EmptyView.vue'
import { usePromptStore } from '@/store/prompts'

import NewPromptForm from './components/NewPromptForm.vue'
import {
  Button,
  TextField,
  Popup,
  Icon,
  DropDown,
  type DropDownItem,
} from '@/components/common'

const promptStore = usePromptStore()
const newPrompt = ref<InstanceType<typeof Popup> | null>(null)

const newFolder = reactive({
  isOpen: false,
  name: '',
})

const menu: DropDownItem[] = [
  {
    id: 'Folder',
    title: 'Folder',
    icon: 'folder2',
    handler: () => (newFolder.isOpen = true),
  },
  {
    id: 'Prompt',
    title: 'Prompt',
    icon: 'fileText',
    handler: () => newPrompt.value?.show(),
  },
]

const handleCreate = () => {
  promptStore.createFolder({ name: newFolder.name })
  newFolder.isOpen = false
  newFolder.name = ''
}

const isEmpty = computed(
  () => promptStore.prompts.length === 0 && promptStore.activities.length === 0,
)

promptStore.getAllPrompts()
promptStore.getAllFolders()
</script>

<template>
  <Page :full-screen="true">
    <template #topBar>
      All Prompts
      <div :class="$style.search">
        <TextField icon="" placeholder="Search Prompt">
          <template #pre>
            <Icon icon="magnifyingGlass" :size="20" />
          </template>
        </TextField>
        <DropDown :menu="menu" :width="144" :close-on-select="true">
          <Button icon="plusSmall" size="2" theme="neutral" variant="solid">
            Create New
          </Button>
        </DropDown>
      </div>
    </template>
    <div v-if="isEmpty" :class="$style.emptyContainer">
      <EmptyView
        icon="folderOpen"
        title="You don’t have any prompts yet."
        description=" Right now, there’s no prompts in this workspace. Let’s create your first
        prompt!"
      />
      <Button
        theme="neutral"
        size="2"
        variant="solid"
        @click="newPrompt?.show()"
        >Create New Prompt</Button
      >
    </div>

    <div v-else :class="$style.container">
      <section>
        <p :class="$style.title">All prompts</p>
        <p :class="$style.description">
          Place where all your prompts are located.
        </p>
        <div>
          <table :class="$style.table">
            <tr v-if="newFolder.isOpen">
              <td :class="$style.newFolder">
                <Icon icon="pencil" :size="16" :class="$style.pencilIcon" />

                <input
                  v-model="newFolder.name"
                  v-focus
                  :class="$style.textField"
                  placeholder="New Folder"
                  @keypress.enter="handleCreate"
                />
              </td>
            </tr>
            <PromptFoldersTable
              :folders="promptStore.sortedFolders"
              :prompts="promptStore.sortedPrompts"
            />
          </table>
        </div>
      </section>
    </div>
  </Page>
  <Popup ref="newPrompt" title="New Prompt">
    <NewPromptForm @close="newPrompt?.hide()" />
  </Popup>
</template>

<style module lang="scss">
.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 32px;
  padding: 32px 32px 64px 32px;
}

.search {
  display: flex;
  gap: 12px;
}

.title {
  color: var(--neutral-12);
  @extend .semibold-4;
}

.description {
  color: var(--neutral-11);
  @extend .regular-4;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 16px;
  border-top: 1px solid var(--neutral-alpha-4);
}

.table {
  width: 100%;
  margin-top: 32px;
}

.newFolder {
  display: flex;
  gap: 16px;
  padding: 12px;
}

.pencilIcon {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  color: var(--neutral-11);
}

.textField {
  @extend .medium-3;
  border: none;
  width: 100%;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--neutral-8);
  }
}

.emptyContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  padding-top: 64px;
}
</style>
