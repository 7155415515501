export type Causer = {
  id: number
  name: string
  firstName: string
  lastName: string
  email: string
  avatar: null
}

export enum Platforms {
  GPT_35 = 'gpt35',
  GPT_4 = 'gpt4',
  GPT_4O = 'gpt4o',
  CLAUDE_35 = 'claude35',
}

export type Outputs = {
  gpt35: string | null
  gpt4: string | null
  gpt4o: string | null
  claude35: string | null
}

type Errors = {
  [key: string]: string | null
}

export type RunResult = {
  id: number
  tokens: number
  name: string
  platforms: Platforms[]
  prompt?: string
  outputs: Outputs
  errors: Errors
  createdAt: string
  user: Causer
}

export type CheckBoxListType = {
  value: boolean
  name: string
  platform: Platforms
}
