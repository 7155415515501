<script setup lang="ts">
import { TextField, Label, Button } from '@/components/common'
import { usePromptStore } from '@/store/prompts'
import { showToast } from '@/utils'

import { ref } from 'vue'

const emits = defineEmits<{
  close: []
}>()
const promptStore = usePromptStore()

const promptName = ref<string>('')
const promptText = ref<string>('')

const handleSubmit = () => {
  promptStore.createPrompt({ name: promptName.value, prompt: promptText.value })
  showToast({ text: 'New prompt created', type: 'success', duration: 2000 })
  emits('close')
}

const handleCancel = (e: Event) => {
  e.preventDefault()
  emits('close')
}
</script>

<template>
  <div :class="$style.container">
    <form @submit.prevent="handleSubmit">
      <div :class="['popup-content', $style.form]">
        <Label>
          Name your prompt
          <TextField v-model="promptName" placeholder="My Prompt" size="2" />
        </Label>

        <Label>
          Prompt text
          <TextField
            v-model="promptText"
            :class="$style.textArea"
            placeholder="Add your prompt here"
            size="2"
            multi-line
          />
        </Label>
      </div>
      <div :class="$style.actions">
        <Button size="3" theme="neutral" variant="outline" @click="handleCancel"
          >Cancel</Button
        >
        <Button size="3" theme="neutral" variant="solid">Create Prompt</Button>
      </div>
    </form>
  </div>
</template>

<style module lang="scss">
.container {
  width: 477px;
}

.textArea {
  height: 156px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 16px;
  border-top: 1px solid var(--neutral-alpha-4);
}
</style>
