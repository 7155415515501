<script setup lang="ts">
import { storeToRefs } from 'pinia'
import LatestResultCards from './LatestResultCards.vue'
import { usePromptStore } from '@/store/prompts'

const promptStore = usePromptStore()
const { sortedRunPromptResults } = storeToRefs(promptStore)
</script>

<template>
  <section :class="$style.container">
    <div>
      <p :class="$style.title">Latest results</p>
      <p :class="$style.description">
        Previous results from running this prompt
      </p>
    </div>
    <p v-if="sortedRunPromptResults.length === 0" :class="$style.notFound">
      No Activity found!
    </p>
    <LatestResultCards v-else />
  </section>
</template>

<style module lang="scss">
.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  .notFound {
    color: var(--neutral-11);
    @extend .regular-2;
  }
}

.title {
  color: var(--neutral-12);
  @extend .semibold-4;
}
.description {
  color: var(--neutral-12);
  @extend .regular-3;
}
</style>
