<script setup lang="ts">
import { ref } from 'vue'
import { showToast } from '@/utils'
import { Button, Icon } from '@/components/common'
import { ColorsMenu } from './constants'
import { usePromptStore } from '@/store/prompts'
const props = defineProps<{
  title: string
  folderColor: string | undefined
  folderId: number
}>()

const emits = defineEmits<{
  close: []
}>()

const promptStore = usePromptStore()
const folderColorNew = ref<string | undefined>(props.folderColor)

const handleColor = (color: string) => {
  folderColorNew.value = color
}

const updateFolderColor = () => {
  promptStore.updateFolder(props.folderId, { color: folderColorNew.value })
  emits('close')
  showToast({ text: 'Folder updated', type: 'success', duration: 2000 })
}
</script>

<template>
  <div :class="['popup-content', $style.container]">
    <p>
      Select folder color for <span :class="$style.title">{{ title }}</span>
    </p>
    <div :class="$style['colors-list']">
      <div
        v-for="color in ColorsMenu"
        :key="color"
        :style="{ backgroundColor: `${color}` }"
        :class="[$style.color, color === folderColor && $style.active]"
        @click="() => handleColor(color)"
      />
    </div>
    <div :class="$style.item">
      <Icon icon="folder2" :size="20" :style="{ color: `${folderColorNew}` }" />
      <span>{{ title }}</span>
    </div>
  </div>
  <div :class="$style.actions">
    <Button size="3" theme="neutral" variant="outline">Cancel</Button>
    <Button size="3" theme="neutral" variant="solid" @click="updateFolderColor"
      >Update</Button
    >
  </div>
</template>
<style module lang="scss">
.container {
  width: 340px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 32px;
  p {
    color: var(--neutral-11);
    @extend .regular-3;
  }

  .item {
    display: flex;
    gap: 8px;
    padding: 6px;
    border-radius: 6px;
    background-color: var(--neutral-alpha-3);
    width: fit-content;
    span {
      color: var(--neutral-12);
      @extend .medium-3;
    }
  }
}

.colors-list {
  display: flex;
  justify-content: flex-start;
  column-gap: 12px;

  .color {
    width: 22px;
    height: 22px;
    border-radius: 8px;
    border: 2px solid var(--background-1);
    padding: 2px;

    &:hover {
      box-shadow: 0px 0px 0px 2px var(--neutral-alpha-4);
    }

    &.active {
      box-shadow: 0px 0px 0px 2px var(--neutral-alpha-12);
    }
  }
}

.title {
  color: var(--neutral-12);
  @extend .medium-3;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 16px;
  border-top: 1px solid var(--neutral-alpha-4);
}
</style>
