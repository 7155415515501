import { RouteRecordRaw } from 'vue-router'
import Base from './index.vue'
import AllPrompts from './AllPrompts.vue'
import SinglePrompt from './SinglePrompt.vue'

const routes: RouteRecordRaw[] = [
  {
    name: 'Prompts',
    path: 'prompts',
    component: Base,
    redirect: { name: 'AllPrompts' },
    children: [
      {
        name: 'AllPrompts',
        path: '',
        component: AllPrompts,
      },
      {
        name: 'Prompt',
        path: ':id',
        component: SinglePrompt,
      },
    ],
  },
]

export default routes
