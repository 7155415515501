import MarkdownIt from 'markdown-it'

import {
  MarkdownParser,
  MarkdownSerializer,
  defaultMarkdownParser,
  defaultMarkdownSerializer,
  schema as markdownSchema,
} from 'prosemirror-markdown'
import { Schema, type NodeSpec } from 'prosemirror-model'

const tagNode: NodeSpec = {
  attrs: {
    id: { default: '' },
    name: { default: '' },
  },

  inline: true,
  group: 'inline',
  draggable: true,

  parseDOM: [
    {
      tag: 'span[data-user-id]',
      getAttrs: (dom) => ({
        id: dom.getAttribute('data-user-id'),
        name: dom.textContent,
      }),
    },
  ],

  toDOM(node) {
    return [
      'span',
      {
        'data-user-id': node.attrs.id,
        class: 'ProseMirror-tagged-user',
      },
      '@' + node.attrs.name,
    ]
  },
}

const extendedSchema = new Schema({
  nodes: markdownSchema.spec.nodes.append({ tag: tagNode }),
  marks: markdownSchema.spec.marks,
  topNode: markdownSchema.spec.topNode,
})

export const markdownParser = new MarkdownParser(
  extendedSchema,
  MarkdownIt('commonmark', { html: false }),
  {
    ...defaultMarkdownParser.tokens,
    tag: {
      node: 'tag',
      getAttrs: (tok) => ({
        id: tok.attrGet('id'),
        name: tok.attrGet('name'),
      }),
    },
  },
)

export const markdownSerializer = new MarkdownSerializer(
  {
    ...defaultMarkdownSerializer.nodes,
    tag(state, node) {
      state.write(`[user-id="${node.attrs.id}" name="${node.attrs.name}"]`)
    },
  },
  defaultMarkdownSerializer.marks,
)
