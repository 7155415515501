import { defineStore } from 'pinia'
import { ActivityLogEntry } from '@/models/prompt'
import { sortByCreatedAtDesc } from '@/utils/helpers'
import { showToast } from '@/utils'
import type {
  FolderResponse,
  PromptResponse,
  PromptPayload,
  FolderPayload,
  PromptUpdatePayload,
  PromptRunPayload,
} from '@/models/prompt'
import type { Activity } from '@/models/activity'
import type { SettingsArray } from '@/models/settings'
import type { CheckBoxListType, RunResult } from '@/models/types'
import { useWorkspaceApi } from '@/composables/api'
import { Platforms } from '@/models/types'

interface PromptState extends ReturnType<typeof useWorkspaceApi> {
  prompts: PromptResponse[]
  folders: FolderResponse[]
  folder: FolderResponse | null
  folderPrompts: PromptResponse[]
  logs: ActivityLogEntry[]
  prompt: PromptResponse | null
  activities: Activity[]
  tokens: number
  checkboxList: CheckBoxListType[]
  runResults: RunResult[]
  result: RunResult | null
  isLoading: boolean
}

export const usePromptStore = defineStore('prompt', {
  state: (): PromptState => ({
    prompts: [],
    folders: [],
    logs: [],
    folderPrompts: [],
    prompt: null,
    activities: [],
    tokens: 100,
    runResults: [],
    folder: null,
    result: null,
    isLoading: false,
    checkboxList: [
      { value: false, name: 'GPT-3.5', platform: Platforms.GPT_35 },
      { value: false, name: 'GPT-4', platform: Platforms.GPT_4 },
      { value: false, name: 'GPT-4o', platform: Platforms.GPT_4O },
      {
        value: false,
        name: 'Claude 3.5 Sonnet',
        platform: Platforms.CLAUDE_35,
      },
    ],
    ...useWorkspaceApi(),
  }),
  getters: {
    sortedFolders(state): FolderResponse[] {
      return sortByCreatedAtDesc(state.folders)
    },
    sortedActivities(state): Activity[] {
      return sortByCreatedAtDesc(state.activities)
    },
    sortedPrompts(state): PromptResponse[] {
      return sortByCreatedAtDesc(state.prompts)
    },
    sortedRunPromptResults(state): RunResult[] {
      return sortByCreatedAtDesc(state.runResults)
    },
    topSixPrompts(): PromptResponse[] {
      return this.sortedPrompts.slice(0, 6)
    },
    checkedPlatforms(state): Platforms[] {
      return state.checkboxList
        .filter((item) => item.value)
        .map((item) => item.platform)
    },
  },
  actions: {
    getAllFolders() {
      return this.api.prompt.getAllFolders().then((data) => {
        this.folders = data
      })
    },
    createFolder(folder: FolderPayload) {
      return this.api.prompt.createFolder(folder).then((data) => {
        this.folders.push(data)
      })
    },
    updateFolder(folderId: number, payload: FolderPayload) {
      const folder = this.folders.find((f) => f.id === folderId)
      if (folder) {
        Object.assign(folder, payload)
      }
      return this.api.prompt.updateFolder(folderId, payload)
    },
    deleteFolder(folderId: number) {
      this.folders = this.folders.filter((f) => f.id !== folderId)
      return this.api.prompt.deleteFolder(folderId)
    },
    getAllPrompts() {
      return this.api.prompt.getAllPrompts().then((data) => {
        this.prompts = data
      })
    },
    getAllPromptsById(id: number) {
      this.api.prompt.getAllPromptsByFolderId(id).then((data) => {
        console.log(data)
        this.folderPrompts = data
      })
    },
    getPromptById(id: number) {
      this.api.prompt.getPromptById(id).then((data) => {
        this.prompt = data
      })
    },
    createPrompt(prompt: PromptPayload) {
      return this.api.prompt.createPrompt(prompt).then((data) => {
        this.prompts.push(data)
      })
    },
    updatePrompt(promptId: number, payload: PromptUpdatePayload) {
      const prompt = this.prompts.find((p) => p.id === promptId)
      if (prompt) {
        Object.assign(prompt, payload)
      }
      return this.api.prompt.updatePrompt(promptId, payload)
    },
    deletePrompt(promptId: number) {
      this.prompts = this.prompts.filter((p) => p.id !== promptId)
      return this.api.prompt.deletePrompt(promptId)
    },
    getPromptActivity(promptId: number) {
      return this.api.prompt.getPromptActivity(promptId).then((data) => {
        this.activities = data
      })
    },
    getPromptRunResults(promptId: number) {
      return this.api.prompt.getPromptRunResults(promptId).then((data) => {
        console.log(data)
        this.runResults = data
      })
    },
    runPrompt(id: number, payload: PromptRunPayload) {
      return this.api.prompt.runPrompt(id, payload)
    },
    getAPIkeys(keys: string) {
      return this.api.prompt.getAPIkeys(keys)
    },
    addAPIkey(settings: SettingsArray) {
      return this.api.prompt.addAPIkey(settings)
    },
    getPromptRun(runId: number) {
      return this.api.prompt.getPromptRun(runId)
    },
    toggleCheckbox(index: number, checked: boolean) {
      this.checkboxList[index].value = checked
    },
    setSliderValue(value: number) {
      this.tokens = value
    },
    async runPromptWithStatus() {
      if (this.checkedPlatforms.length === 0) {
        showToast({
          text: 'Please select your LLM',
          type: 'error',
        })
        return
      }
      const payload = {
        tokens: this.tokens,
        platforms: this.checkedPlatforms,
      }
      if (this.prompt) {
        this.isLoading = true
        try {
          const data = await this.runPrompt(this.prompt.id, payload)
          await this.checkPromptStatus(data?.id)
        } catch (error) {
          console.error('Error running prompt:', error)
          showToast({
            text: 'An error occurred while running the prompt',
            type: 'error',
          })
        } finally {
          this.isLoading = false
        }
      }
    },
    async checkPromptStatus(promptId: number) {
      const check = true
      while (check) {
        const data = await this.getPromptRun(promptId)
        if (data.isReady) {
          this.runResults.push(data)
          break
        }
        await new Promise((resolve) => setTimeout(resolve, 5000))
      }
    },
  },
})
