<script setup lang="ts">
import { ref, reactive } from 'vue'
import {
  Icon,
  Button,
  Popup,
  DropDown,
  Checkbox,
  Confirm,
  type DropDownItem,
} from '@/components/common'
import type { PromptResponse } from '@/models/prompt'
import { usePromptStore } from '@/store/prompts'
import { ISODate } from '@/utils/helpers'
import { showToast } from '@/utils'
import MovePopupContent from './PopupsContent/MovePopupContent.vue'

const props = defineProps<{ prompt: PromptResponse }>()

const movePopup = ref<InstanceType<typeof Popup> | null>(null)
const confirmPopup = ref<InstanceType<typeof Confirm> | null>(null)
const isChecked = ref<boolean>(false)
const promptStore = usePromptStore()
const showMovePopup = () => {
  movePopup.value?.show()
}

const rename = reactive({
  isActive: false,
  name: props.prompt.name,
})

const deletePrompt = () => {
  promptStore.deletePrompt(props.prompt.id)
  showToast({
    text: 'Prompt has been deleted.',
    type: 'success',
    duration: 2000,
  })
}

const handleUpdate = () => {
  rename.name.length &&
    promptStore.updatePrompt(props.prompt.id, { name: rename.name })

  rename.isActive = false
}

const showDeletePopup = () => {
  confirmPopup.value?.show({
    title: 'Delete prompt',
    message: `You’re about to delete ${props.prompt.name} prompt. Are you sure? This is permanent action and can not be undone. `,
    actions: [
      {
        title: 'Cancel',
        theme: 'neutral',
        variant: 'ghost',
      },
      {
        title: `Delete prompt`,
        theme: 'danger',
        isConfirm: true,
        handler: () => deletePrompt(),
      },
    ],
  })
}
const handleCheckbox = () => {
  isChecked.value = !isChecked.value
}

const menu: DropDownItem[] = [
  {
    id: 'rename',
    title: 'Rename',
    handler: () => (rename.isActive = true),
  },
  {
    id: 'Move',
    title: 'Move',
    handler: () => showMovePopup(),
  },
  {
    id: 'delete',
    title: 'Delete',
    handler: () => showDeletePopup(),
  },
]
</script>

<template>
  <RouterLink
    v-slot="{ navigate }"
    custom
    :to="{
      name: 'Prompt',
      params: { id: prompt.id },
    }"
  >
    <tr :class="$style.row">
      <td :class="$style.description" @click="navigate">
        <Checkbox :model-value="isChecked" @click.prevent="handleCheckbox" />
        <Icon icon="fileText" :size="20" />
        <input
          v-if="rename.isActive"
          v-model="rename.name"
          v-focus
          :class="$style.textField"
          type="text"
          @keypress.enter="handleUpdate"
        />
        <p v-else>
          {{ prompt.name }}
          <!-- <Badge
          v-if="prompt.category"
          size="2"
          :class="$style.badge"
          :style="{ color: prompt.color, backgroundColor: prompt.bgColor }"
          >{{ prompt.category }}</Badge
        > -->
        </p>
      </td>

      <td :class="$style.date" width="160" @click="navigate">
        {{ ISODate(prompt.createdAt, { format: 'LLL dd' }) }}
      </td>
      <td width="43">
        <DropDown :width="144" :menu="menu" :close-on-select="true">
          <Button
            size="2"
            variant="ghost"
            theme="neutral"
            icon="dotGrid1x3Horizontal"
          />
        </DropDown>
        <Popup ref="movePopup" title="Move">
          <MovePopupContent
            :title="prompt.name"
            :prompt-id="prompt.id"
            @close="movePopup?.hide()"
          />
        </Popup>
        <Confirm ref="confirmPopup" />
      </td>
    </tr>
  </RouterLink>
</template>

<style module lang="scss">
.row {
  cursor: pointer;
  &:hover {
    background-color: var(--neutral-alpha-3);
  }
  &:not(:last-child) {
    border-bottom: 1px solid var(--neutral-alpha-4);
  }
  td {
    padding: 12px;
  }
  .date {
    color: var(--neutral-11);
    @extend .regular-3;
  }
  .description {
    display: flex;
    gap: 16px;
    p {
      @extend .medium-3;
      color: var(--neutral-12);
      .badge {
        margin-left: 6px;
      }
    }
  }
}

.textField {
  @extend .medium-3;
  border: none;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--neutral-8);
  }
}
</style>
