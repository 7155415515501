<script setup lang="ts">
import type { RunResult } from '@/models/types'
import { storeToRefs } from 'pinia'
import { Avatar } from '@/components/common'
import { usePromptStore } from '@/store/prompts'
import { ISODate } from '@/utils/helpers'

const promptStore = usePromptStore()
const { sortedRunPromptResults, result } = storeToRefs(promptStore)

const selectResult = (r: RunResult) => {
  result.value = r
}
</script>

<template>
  <ul :class="$style.cards">
    <li
      v-for="result in sortedRunPromptResults"
      :key="result.id"
      :class="$style.card"
      @click="selectResult(result)"
    >
      <div :class="$style.result">
        <div :Class="$style.resultInfo">
          <div :class="$style.userInfo">
            <Avatar :title="result?.user?.name" :size="20" />
            <p>{{ result?.user?.name }}</p>
          </div>
          <p :class="$style.date">
            {{ ISODate(result.createdAt, { format: 'LLL dd' }) }}
          </p>
        </div>

        <div :class="$style.models">
          <template v-for="(model, index) in result.platforms" :key="model">
            <span :class="$style.model">{{ model }}</span>
            <span v-if="index < result.platforms.length - 1" :class="$style.dot"
              >.</span
            >
          </template>
        </div>
      </div>
    </li>
  </ul>
</template>

<style module lang="scss">
.cards {
  display: flex;
  flex-direction: column;
  gap: 4px;
  .card {
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 4px;
    border-radius: 4px;
    background: var(--neutral-alpha-3);
    cursor: pointer;
    .result {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .resultInfo {
        display: flex;
        justify-content: space-between;
        align-self: stretch;
        .userInfo {
          display: flex;
          gap: 6px;
        }
      }
    }

    &:first-child {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    &:last-child {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
}

p {
  color: var(--neutral-12);
  @extend .medium-3;
}

.models {
  display: flex;
  flex-grow: 1;
  gap: 5px;
}

.model {
  color: var(--neutral-11);
  @extend .regular-3;
  text-transform: uppercase;
}
.dot {
  margin: -2px 0;
}
</style>
