<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue'

const props = defineProps({
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
  },
  step: {
    type: Number,
    default: 1,
  },
  modelValue: {
    type: [Number, String],
    required: true,
  },
})

const emit = defineEmits(['update:modelValue'])

const value = ref<number | string>(props.modelValue)
const sliderInput = ref<HTMLInputElement | null>(null)

const updateValue = () => {
  emit('update:modelValue', value.value)
  updateSliderBackground()
}

const updateSliderBackground = () => {
  if (sliderInput.value) {
    const percent =
      ((Number(value.value) - props.min) / (props.max - props.min)) * 100
    sliderInput.value.style.background = `linear-gradient(to right, #2c2c2c 0%, #2c2c2c ${percent}%, #e0e1e6 ${percent}%, #e0e1e6 100%)`
  }
}

const valueStyle = computed(() => {
  const percent =
    ((Number(value.value) - props.min) / (props.max - props.min)) * 100
  return {
    left: `calc(${percent}% + (${8 - percent * 0.15}px))`,
    transform: 'translate(-50%, -100%)',
  }
})

onMounted(() => {
  updateSliderBackground()
})

watch(
  () => props.modelValue,
  (newValue) => {
    value.value = newValue
    updateSliderBackground()
  },
)
</script>

<template>
  <div class="slider-container">
    <input
      ref="sliderInput"
      v-model="value"
      type="range"
      :min="min"
      :max="max"
      :step="step"
      class="slider"
      @input="updateValue"
    />
    <div class="slider-value" :style="valueStyle">{{ value }}</div>
  </div>
</template>

<style scoped>
.slider-container {
  width: 100%;
  position: relative;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 9999px;
  background: #e0e1e6;
  outline: none;
  opacity: 1;
  transition: opacity 0.2s;
  margin: auto 0;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border: none;
}

.slider::-moz-range-track {
  background: none;
}

.slider::-moz-range-progress {
  background: none;
}

.slider-value {
  position: absolute;
  top: -10px;
  background-color: #2c2c2c;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 14px;
  pointer-events: none;
  white-space: nowrap;
}

.slider-value::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #2c2c2c transparent transparent transparent;
}
</style>
