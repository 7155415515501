<script setup lang="ts">
import { ref, reactive } from 'vue'
import { FolderResponse } from '@/models/prompt'
import { ISODate } from '@/utils/helpers'
import { showToast } from '@/utils'
import { usePromptStore } from '@/store/prompts'

import {
  DropDown,
  Button,
  Popup,
  Icon,
  Confirm,
  Checkbox,
  type DropDownItem,
} from '@/components/common'

import MovePopupContent from '@/components/shared/PopupsContent/MovePopupContent.vue'
import ChangeFolderColorPopupContent from '@/components/shared/PopupsContent/ChangeFolderColorPopupContent.vue'

const props = defineProps<{
  folder: FolderResponse
}>()

const rename = reactive({
  isActive: false,
  name: props.folder.name,
})

const movePopup = ref<InstanceType<typeof Popup> | null>(null)
const changeFolderColorPopup = ref<InstanceType<typeof Popup> | null>(null)
const confirmPopup = ref<InstanceType<typeof Confirm> | null>(null)

const isChecked = ref<boolean>(false)
const favorite = ref<boolean>(false)
const promptStore = usePromptStore()

const deleteFolder = () => {
  promptStore.deleteFolder(props.folder.id)
  showToast({
    text: 'Folder has been deleted',
    type: 'success',
    duration: 2000,
  })
}

const showDeletePopup = () => {
  confirmPopup.value?.show({
    title: 'Delete folder',
    message: `You’re about to delete ${props.folder.name} folder. Are you sure? This is permanent action and can not be undone. `,
    actions: [
      {
        title: 'Cancel',
        theme: 'neutral',
        variant: 'ghost',
      },
      {
        title: `Delete folder`,
        theme: 'danger',
        isConfirm: true,
        handler: () => deleteFolder(),
      },
    ],
  })
}

const menu: DropDownItem[] = [
  {
    id: 'rename',
    title: 'Rename',
    handler: () => (rename.isActive = true),
  },
  {
    id: 'move',
    title: 'Move',
    handler: () => movePopup.value?.show(),
  },
  {
    id: 'change',
    title: 'Change Color',
    handler: () => changeFolderColorPopup.value?.show(),
  },
  {
    id: 'favorite',
    title: favorite.value ? 'Remove favorites' : 'Add to favorites',
    handler: () => console.log('FAVORITE'),
  },
  {
    id: 'delete',
    title: 'Delete',
    handler: () => showDeletePopup(),
  },
]

const handleUpdate = () => {
  rename.name.length &&
    promptStore.updateFolder(props.folder.id, { name: rename.name })

  rename.isActive = false
}

const handleCheckbox = () => {
  isChecked.value = !isChecked.value
}
</script>

<template>
  <RouterLink
    v-slot="{ navigate }"
    custom
    :to="{
      name: 'Folder',
      params: { id: folder.id },
    }"
  >
    <tr :class="$style.row">
      <td :class="$style.description" @click="navigate">
        <Checkbox :model-value="isChecked" @click.prevent="handleCheckbox" />
        <Icon
          :icon="folder.icon ? folder.icon : 'folder2'"
          :style="{ color: folder.color }"
          :size="20"
        />
        <input
          v-if="rename.isActive"
          v-model="rename.name"
          v-focus
          :class="$style.textField"
          type="text"
          @keypress.enter="handleUpdate"
        />
        <p v-else>
          {{ folder.name }}
        </p>
        <p :class="$style.count">{{ folder.promptsCount || 0 }} prompts</p>
      </td>

      <td :class="$style.date" width="160" @click="navigate">
        {{ ISODate(folder.createdAt, { format: 'LLL dd' }) }}
      </td>
      <td width="43">
        <DropDown :width="144" :menu="menu" :close-on-select="true">
          <Button
            size="2"
            variant="ghost"
            theme="neutral"
            icon="dotGrid1x3Horizontal"
          />
        </DropDown>
        <Popup ref="movePopup" title="Move">
          <MovePopupContent :title="folder.name" />
        </Popup>
        <Popup ref="changeFolderColorPopup" title="Change folder color">
          <ChangeFolderColorPopupContent
            :title="folder.name"
            :folder-color="folder.color"
            :folder-id="folder.id"
            @close="changeFolderColorPopup?.hide()"
          />
        </Popup>

        <Confirm ref="confirmPopup" />
      </td>
    </tr>
  </RouterLink>
</template>

<style module lang="scss">
.row {
  cursor: pointer;
  &:hover {
    background-color: var(--neutral-alpha-3);
  }
  &:not(:last-child) {
    border-bottom: 1px solid var(--neutral-alpha-4);
  }
  td {
    padding: 12px;
  }
  .date {
    color: var(--neutral-11);
    @extend .regular-3;
  }
  .description {
    display: flex;
    gap: 16px;
    p {
      @extend .medium-3;
      color: var(--neutral-12);
      .badge {
        margin-left: 6px;
      }
    }
    .count {
      color: var(--neutral-11);
      @extend .regular-3;
    }
  }
}

.textField {
  @extend .medium-3;
  border: none;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--neutral-8);
  }
}
</style>
