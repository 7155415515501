<script setup lang="ts">
import { Checkbox, Icon, Button } from '@/components/common'
import { ref } from 'vue'
import { showToast } from '@/utils'
import { usePromptStore } from '@/store/prompts'

const promptStore = usePromptStore()

const props = defineProps<{
  title: string
  promptId?: number
}>()

const emits = defineEmits<{
  close: []
}>()

const selectedFolderId = ref<number | null>(null)

const handleFolderSelect = (folderId: number) => {
  if (folderId !== undefined) {
    selectedFolderId.value = folderId
  }
}

const movePrompt = () => {
  if (selectedFolderId.value !== null && props.promptId !== undefined) {
    promptStore.updatePrompt(props.promptId, {
      prompt_folder_id: selectedFolderId.value,
    })
    emits('close')
    showToast({ text: 'Prompt Moved', type: 'success', duration: 2000 })
  }
}
</script>

<template>
  <div :class="['popup-content', $style.container]">
    <p :class="$style.description">
      Select folder where you want to move
      <span :class="$style.title"> {{ title }} </span>
      prompt.
    </p>
    <div :class="$style.folders">
      <div
        v-for="folder in promptStore.sortedFolders"
        :key="folder.id"
        :class="$style.item"
        @click="handleFolderSelect(folder.id)"
      >
        <Checkbox
          :model-value="selectedFolderId === folder.id"
          :is-radio="true"
          @update:model-value="handleFolderSelect(folder.id)"
        />
        <Icon
          :icon="folder.icon ? folder.icon : 'folder2'"
          :size="20"
          :style="{ color: folder.color }"
        />
        {{ folder.name }}
      </div>
    </div>
  </div>
  <div :class="$style.actions">
    <Button size="3" theme="neutral" variant="outline">Cancel</Button>
    <Button size="3" theme="neutral" variant="solid" @click="movePrompt"
      >Move Prompt</Button
    >
  </div>
</template>

<style module lang="scss">
.container {
  width: 340px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  .description {
    color: var(--neutral-11);
    @extend .regular-3;
    .title {
      color: var(--neutral-12);
      @extend .medium-3;
    }
  }

  .folders {
    display: flex;
    flex-direction: column;
    height: 200px;
    overflow-y: auto;
    gap: 20px;
    // Styling the scrollbar
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: var(--neutral-3);
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--neutral-6);
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: var(--neutral-8);
    }

    .item {
      display: flex;
      gap: 16px;
      align-items: center;
      cursor: pointer;
      @extend .medium-3;
      color: var(--neutral-12);
    }
  }
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 16px;
  border-top: 1px solid var(--neutral-alpha-4);
}
</style>
