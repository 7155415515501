<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { Page, Breadcrumbs, type Crumb } from '@/components/common/layout'
import { usePromptStore } from '@/store/prompts'
import { Button, Popup, Confirm } from '@/components/common'
import PromptInfo from './components/PromptInfo.vue'
import LatestResult from './components/LatestResult.vue'
import PromptHistory from './components/PromptHistory.vue'
import RunPromptPopup from './components/RunPromptPopup.vue'
import Result from './components/Result.vue'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'

const route = useRoute()
const router = useRouter()
const promptId = ref(Number(route.params.id))
const promptStore = usePromptStore()
const runPrompt = ref<InstanceType<typeof Popup> | null>(null)
const confirmPopup = ref<InstanceType<typeof Confirm> | null>(null)
const { result, prompt } = storeToRefs(promptStore)

const showDeletePopup = () => {
  confirmPopup.value?.show({
    title: 'Delete folder',
    message: `You’re about to delete ${promptStore.prompt?.name} prompt. Are you sure? This is permanent action and can not be undone. `,
    actions: [
      {
        title: 'Cancel',
        theme: 'neutral',
        variant: 'ghost',
      },
      {
        title: `Delete prompt`,
        theme: 'danger',
        isConfirm: true,
        handler: () => deletePrompt(),
      },
    ],
  })
}

const deletePrompt = () => {
  promptStore.deletePrompt(promptId.value).then(() => {
    router.push({ name: 'AllPrompts' })
  })
}

onMounted(() => {
  promptStore.getPromptById(promptId.value)
  promptStore.getPromptActivity(promptId.value)
  promptStore.getPromptRunResults(promptId.value)
})

const crumbs = computed<Crumb[]>(() => [
  {
    title: promptStore.prompt?.name || '',
    url: { name: 'AllPrompts' },
  },
])

onBeforeUnmount(() => {
  prompt.value = null
})
</script>

<template>
  <Page :full-screen="true">
    <template #topBar>
      <Breadcrumbs :crumbs="crumbs" />
      <div :class="$style.actions">
        <Button
          size="2"
          icon="trashCan2Outline"
          theme="neutral"
          variant="ghost"
          @click="showDeletePopup"
        />
        <Button size="2" theme="neutral" variant="outline">Share</Button>
        <Button
          size="2"
          theme="neutral"
          variant="solid"
          @click="runPrompt?.show()"
          >Run Prompt</Button
        >
      </div>
    </template>
    <div :class="$style.container">
      <section v-if="promptStore.prompt && !result" :class="$style.content">
        <PromptInfo :prompt="promptStore.prompt" />
        <hr />
        <LatestResult />
        <hr />
        <PromptHistory />
      </section>
      <Result v-else />
    </div>
    <Popup ref="runPrompt" title="Run prompt">
      <RunPromptPopup @close="runPrompt?.hide()" />
    </Popup>
    <Confirm ref="confirmPopup" />
  </Page>
</template>

<style module lang="scss">
.container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 32px;
  padding: 32px 32px 64px 32px;
  .content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 772px;
    margin: 0 auto;
  }
}

.actions {
  display: flex;
  gap: 8px;
}
</style>
