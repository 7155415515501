<script setup lang="ts">
import { usePromptStore } from '@/store/prompts'
import { computed } from 'vue'
import { ISODate } from '@/utils/helpers'

const promptStore = usePromptStore()

const lastActivity = computed(
  () => promptStore.activities.slice().pop()?.createdAt,
)
</script>
<template>
  <div :class="$style.card">
    <p :class="$style.title">Last update</p>
    <h2 :class="$style.value">
      {{ lastActivity ? ISODate(lastActivity, { format: 'LLL dd' }) : '_' }}
    </h2>
  </div>
  <div :class="$style.card">
    <p :class="$style.title">Last used</p>
    <h2 :class="$style.value">_ _ _</h2>
  </div>
  <div :class="$style.card">
    <p :class="$style.title">Avg. cost</p>
    <h2 :class="$style.value">_ _ _</h2>
  </div>
</template>

<style module lang="scss">
.card {
  padding: 12px 16px;
  flex: 1 1 calc(25% - 32px);
  min-width: 236px;
  border-radius: 8px;
  border: 1px solid var(--neutral-alpha-4);

  .title {
    color: var(--neutral-11);
    @extend .medium-3;
  }

  .value {
    color: var(--neutral-12);
    @extend .semibold-6;
  }
}
</style>
