import type { AxiosInstance } from 'axios'
import type { Activity } from '@/models/activity'
import type {
  FolderPayload,
  PromptPayload,
  FolderResponse,
  PromptResponse,
  PromptUpdatePayload,
  PromptRunPayload,
} from '@/models/prompt'
import type { RunResult } from '@/models/types'
import type { SettingsArray } from '@/models/settings'

export class PromptService {
  constructor(private instance: AxiosInstance) {}

  // Folder endpoints
  getAllFolders = () =>
    this.instance
      .get<FolderResponse[]>(`/folders`)
      .then((response) => response.data)

  createFolder = (folderData: FolderPayload) =>
    this.instance
      .post<FolderResponse>(`/folders`, folderData)
      .then((response) => response.data)

  updateFolder = (id: number, folderData: FolderPayload) =>
    this.instance
      .put<FolderPayload>(`/folders/${id}`, folderData)
      .then((response) => response.data)

  deleteFolder = (id: number) =>
    this.instance.delete(`/folders/${id}`).then((response) => response.data)
  // Prompt endpoints
  getAllPrompts = () =>
    this.instance
      .get<PromptResponse[]>(`/prompts`)
      .then((response) => response.data)
  getPromptById = (id: number) =>
    this.instance
      .get<PromptResponse>(`/prompts/${id}`)
      .then((response) => response.data)

  getAllPromptsByFolderId = (id: number) =>
    this.instance
      .get<PromptResponse[]>(`/prompts`, {
        params: { folder_id: id },
      })
      .then((response) => response.data)

  createPrompt = (promptData: PromptPayload) =>
    this.instance
      .post<PromptResponse>(`/prompts`, promptData)
      .then((response) => response.data)

  updatePrompt = (id: number, promptData: PromptUpdatePayload) =>
    this.instance
      .put<PromptResponse>(`/prompts/${id}`, promptData)
      .then((response) => response.data)

  deletePrompt = (id: number) =>
    this.instance.delete(`/prompts/${id}`).then((response) => response.data)

  getPromptActivity = (id: number) =>
    this.instance
      .get<Activity[]>(`/prompts/${id}/activity`)
      .then((response) => response.data)
  runPrompt = (id: number, payload: PromptRunPayload) =>
    this.instance
      .post<RunResult>(`/prompts/${id}/runs`, payload)
      .then((response) => response.data)
  getPromptRunResults = (id: number) =>
    this.instance.get(`/prompts/${id}/runs`).then((response) => response.data)
  addAPIkey = (payload: SettingsArray) =>
    this.instance
      .post('/settings', { settings: payload })
      .then((response) => response.data)
  getAPIkeys = (keys: string) =>
    this.instance
      .get(`/settings`, { params: { keys } })
      .then((response) => response.data)
  getPromptRun = (runId: number) =>
    this.instance.get(`/runs/${runId}`).then((response) => response.data)
}
