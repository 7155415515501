import { defineStore } from 'pinia'
import useApi from '@/composables/api'
import type User from '@/models/user'
import { useWorkspaceStore } from './workspace'
import { tracker } from '@/utils'
import { brevo } from '@/utils/brevo'

type ApplicationState = {
  user: null | User
  voted: boolean | null
  sidebars: { left: boolean; right: boolean }
} & ReturnType<typeof useApi>

export const useApplicationStore = defineStore('application', {
  state: (): ApplicationState => ({
    user: null,
    voted: null,
    sidebars: { left: true, right: true },
    ...useApi(),
  }),
  actions: {
    async getUser(id = 'me') {
      if (id === 'me' && this.user) return this.user

      const result = await this.api.account.getById(id)

      if (id === 'me' && result) {
        this.user = result
        tracker.trackIdentity(this.user)
        brevo.initializeUser(this.user)

        const workspaceStore = useWorkspaceStore()
        workspaceStore.setWorkspaces(this.user.workspaces)
      }

      return result
    },
    async updateUser(body: Partial<User>, avatar?: File) {
      if (!this.user) return

      if (avatar) {
        const updated = await this.api.account.uploadAvatar(avatar)
        this.user.avatar = updated
      }

      return this.api.account.update(body).then(() => {
        if (!this.user) return
        Object.assign(this.user, {
          ...body,
          name: body.name,
        })
      })
    },
    updateEmail(email: string, password: string) {
      return this.api.account.updateEmail(email, password).then(() => {
        if (!this.user) return
        this.user.email = email
      })
    },
    signOut() {
      tracker.resetIdentity()
      brevo.reset()

      return this.api.auth.signOut().then(() => {
        window.location.href = '/login'
      })
    },
    deleteAccount() {
      tracker.resetIdentity()
      brevo.reset()

      return this.api.account.deleteAccount()
    },
    async checkVote() {
      const voted = await this.api.workspace.checkVote()
      this.voted = voted
    },
    toggleLeftBar() {
      this.sidebars.left = !this.sidebars.left
    },
    toggleRightBar() {
      this.sidebars.right = !this.sidebars.right
    },
  },
})
