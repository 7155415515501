<script setup lang="ts">
import { ref, onMounted } from 'vue'
import type { SettingsArray } from '@/models/settings'
import SettingsHeader from './SettingsHeader.vue'
import { Button, TextField } from '@/components/common'
import { usePromptStore } from '@/store/prompts'
import { showToast } from '@/utils'

const openaiKey = ref('')
const anthropicKey = ref('')
const store = usePromptStore()

const fetchKeys = () => {
  const keys = ['openai_key', 'anthropic_key'].join(',')
  store.getAPIkeys(keys).then((data) => {
    anthropicKey.value = data.anthropic_key
    openaiKey.value = data.openai_key
  })
}

const save = () => {
  const settings: SettingsArray = [
    { key: 'openai_key', value: openaiKey.value },
    { key: 'anthropic_key', value: anthropicKey.value },
  ]

  store.addAPIkey(settings).then(() => {
    showToast({ text: 'Settings saved successfully' })
  })
}

onMounted(fetchKeys)
</script>

<template>
  <div>
    <SettingsHeader
      title="API keys"
      subtitle="Manage your API keys for different LLMs"
    />
    <hr :class="$style.separator" />
    <div :class="$style.field">
      <div :class="$style.label">OpenAI (GPT)</div>
      <TextField
        v-model="openaiKey"
        size="2"
        placeholder="Add OpenAI API key"
        :class="$style.value"
      />
    </div>

    <div :class="$style.field">
      <div :class="$style.label">Claude API</div>
      <TextField
        v-model="anthropicKey"
        size="2"
        placeholder="Add Claude API key"
        :class="$style.value"
      />
    </div>
    <Button variant="soft" theme="neutral" size="2" @click="save">
      Save changes
    </Button>
  </div>
</template>

<style lang="scss" module>
@import url('./styles.scss');
</style>
